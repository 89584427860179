<template>
	<div class='contain'>
		<!-- 企业认证 -->
		<img src="@/assets/imgs/com-auth-bg.png" alt="">

		<div class="main">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :hide-required-asterisk="true" label-position="top" class="auth-form">
				<div class="title fs_20 color_333"><span class="bold mr_5">个人信息</span><span class="sub_color fs_16">(必填)</span></div>
				<el-row class="mt_20" :gutter="20">
					<el-col :span="6">
						<el-form-item label="真实姓名" prop="realname">
							<el-input type="text" placeholder="请填写真实姓名" v-model.trim="ruleForm.realname" :disabled="verify_status==1?true:false"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="身份证号码" prop="idcard">
							<el-input type="text" @input="handleInput('idcard')" placeholder="请填写身份证号码" v-model.trim="ruleForm.idcard" :disabled="verify_status==1?true:false"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="部门" prop="dept_name">
							<el-input type="text" v-model.trim="ruleForm.dept_name" min="1" placeholder="请填写部门"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="职位" prop="job_name">
							<el-input type="text" v-model.trim="ruleForm.job_name" min="1" placeholder="请填写职位"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<br><br>
				<div class="title fs_20 color_333"><span class="bold mr_5">企业信息</span><span class="sub_color fs_16">(必填)</span></div>
				<el-row class="mt_20" :gutter="20">
					<el-col :span="12">
						<el-form-item label="公司名称" prop="company">
							<el-input type="text" placeholder="请填写公司名称" readonly v-model.trim="ruleForm.company"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="公司规模" prop="staff_num_range">
							<el-select v-model.trim="ruleForm.staff_num_range" placeholder="请选择公司规模" style="width:100%;">
								<el-option v-for="item in scaleOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<div class="flex_c">
							<p class="fs_14" style="margin-right:30px;">
								<span class="color_999 mr_5">信用代码：</span>
								<span class="color_333">{{ ruleForm.creditcode }}</span>
							</p>
							<p class="fs_14" v-show="trade">
								<span class="color_999 mr_5">所属行业：</span>
								<span class="color_333">{{ trade }}</span>
							</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-top:40px;">
						<div class="flex company_logo">
							<el-form-item label="公司LOGO" prop="logo" class="mr_10">
                                <el-upload ref="uploadlogo" :action="`${baseApi}/api/v2/upload/image?filename=file&type=company`" :headers="headers" accept=".jpg,.jpeg,.png" :show-file-list="false" :on-success="handleLogoSuccess" :before-upload="beforeLogoUpload" :on-progress="handleLogoProgress">
                                    <img v-if="ruleForm.logo" :src="logo_tmp" class="file-img">
                                    <div class="flex_cen" v-else>
                                        <img src="@/assets/imgs/icon/img-icon.png" class="img-icon" v-if="logoProgress==0">
                                        <el-progress type="circle" :percentage="logoProgress" width="90" class="mt_5" v-else></el-progress>
                                    </div>
                                </el-upload>
							</el-form-item>
                            <div class="flex_1 mt_10">
                                <p class="info_color fs_14">温馨提示：</p>
                                <div class="mt_10 sub_color">
                                    <p class="fs_12">若暂时无法上传企业LOGO</p>
                                    <p class="fs_12">可从以下选择一个作为替代</p>
                                </div>
                            </div>
						</div>
                        <div class="logo_tmp flex_around">
                            <div class="myLogo" @click="selectLogo(1)">
                                <img src="../../assets/imgs/icon/logo1.png" alt="" ref="logo1">
                                <img :src="companySeal1.url" class="companySeal" alt="">
                                <img src="../../assets/imgs/icon/check.png" class="check-icon" v-show="tmpIndex==1" alt="">
                            </div>
                            <div class="myLogo" @click="selectLogo(2)">
                                <img src="../../assets/imgs/icon/logo2.png" alt="" ref="logo2">
                                <img :src="companySeal2.url" class="companySeal" alt="">
                                <img src="../../assets/imgs/icon/check.png" class="check-icon" v-show="tmpIndex==2" alt="">
                            </div>
                            <div class="myLogo" @click="selectLogo(3)">
                                <img src="../../assets/imgs/icon/logo3.png" alt="" ref="logo3">
                                <img :src="companySeal3.url" class="companySeal" alt="">
                                <img src="../../assets/imgs/icon/check.png" class="check-icon" v-show="tmpIndex==3" alt="">
                            </div>
                            <div class="myLogo" @click="selectLogo(4)">
                                <img src="../../assets/imgs/icon/logo4.png" alt="" ref="logo4">
                                <img :src="companySeal4.url" class="companySeal" alt="">
                                <img src="../../assets/imgs/icon/check.png" class="check-icon" v-show="tmpIndex==4" alt="">
                            </div>
                            <div class="myLogo" @click="selectLogo(5)">
                                <img :src="companySeal5.url" alt="">
                                <img src="../../assets/imgs/icon/check.png" class="check-icon" v-show="tmpIndex==5" alt="">
                            </div>
                        </div>
					</el-col>
					<el-col :span="12" style="margin-top:40px;">
						<div class="flex">
							<el-form-item label="营业执照" prop="license" class="mr_10 yyzz">
                                <el-upload :action="`${baseApi}/api/v2/upload/image?filename=file&type=company`" :headers="headers" accept=".jpg,.jpeg,.png" :show-file-list="false" :on-success="handleLicenseSuccess" :before-upload="beforeLicenseUpload" :on-progress="handleLicenseProgress">
                                    <img v-if="ruleForm.license" :src="license_tmp" class="file-img">
                                    <div class="flex_cen" v-else>
                                        <img src="@/assets/imgs/icon/img-icon.png" class="img-icon" v-if="licenseProgress==0">
                                        <el-progress type="circle" :percentage="licenseProgress" width="90" class="mt_5" v-else></el-progress>
                                    </div>
                                </el-upload>
							</el-form-item>
                            <div class="flex_1 mt_10">
                                <p class="info_color fs_14">证件要求：</p>
                                <div class="mt_10 sub_color">
                                    <p class="fs_12">必须为清晰、完整的彩色原件扫描件或数码照</p>
                                    <p class="fs_12">仅支持 .jpg .jpeg .png 的图片格式，图片大小不超过2M</p>
                                    <p class="fs_12">必须在有效期内且年检章齐全（当年成立的公司可无年检章）</p>
                                </div>
                            </div>
						</div>
					</el-col>
				</el-row>
				<div class="flex_cen">
					<el-button type="default" class="fs_18" @click="toIndex">体验看看</el-button>
					<el-button type="primary" class="fs_18" @click="submitForm('ruleForm')">提 交</el-button>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getToken, removeToken, l_setItem, s_getItem, s_removeItem } from '@/common/cache'
import creatLogo from '@/common/getKeywords'
import createSeal from '@/common/creatSeal'
import axios from 'axios'
export default {
	name: 'Auth',
	data() {
		var checkIdcard = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入身份证号'))
			} else if (value.length!=18) {
				callback(new Error('请输入正确的身份证号'))
			} else {
				callback()
			}
		}
		return {
			authInfo: {},
			companySeal1: '',
			companySeal2: '',
			companySeal3: '',
			companySeal4: '',
			companySeal5: '',
			tmpIndex: 0,
			baseApi: process.env.VUE_APP_API,
			headers: {
				'Authorization': 'Bearer ' + getToken()
			},
			trade: '', // 所属行业
			ruleForm: {
				creditcode: '', // 信用代码
				company: '',
				realname: '',
				idcard: '',
				dept_name: '',
				job_name: '',
				staff_num_range: '', // 公司规模
				logo: '',
				license: '' // 营业执照
			},
			verify_status: 0,
			logo_tmp: '',
			license_tmp: '',
			logoProgress: 0, // logo 上传进度
			licenseProgress: 0, // 营业执照上传进度
			scaleOptions: [
				{ value: "100以下", label: "100人以下" },
				{ value: "101-300", label: "101-300人" },
				{ value: "301-1000", label: "301-1000人" },
				{ value: "1001-3000", label: "1001-3000人" },
				{ value: "3001-10000", label: "3001-10000人" },
				{ value: "10001-30000", label: "10001-30000人" },
				{ value: "30001以上", label: "30001人以上" }
			],
			rules: {
				company: [
					{ required: true, message: '请输入企业名称', trigger: 'change' }
				],
				realname: [
					{ required: true, message: '请输入姓名', trigger: 'change' }
				],
				idcard: [
					{ validator: checkIdcard, trigger: 'blur' }
				],
				dept_name: [
					{ required: true, message: '请输入部门', trigger: 'change' }
				],
				job_name: [
					{ required: true, message: '请输入职位', trigger: 'change' }
				],
				staff_num_range: [
					{ required: true, message: '请选择公司规模', trigger: 'change' }
				],
				logo: [
					{ required: true, message: '请上传公司LOGO', trigger: 'change' }
				],
				license: [
					{ required: true, message: '请上传营业执照', trigger: 'change' }
				]
			}
		}
	},
	created() {
		let authInfo = s_getItem('authInfo') || {}
		this.authInfo = authInfo.company
		if(authInfo.user) {
			let { company, user } = authInfo
			this.ruleForm.company = company.company_name
			this.ruleForm.creditcode = company.creditcode
			this.ruleForm.realname = user.realname || ''
			this.ruleForm.idcard = user.idcard || ''
			this.verify_status = user.verify_status
		}else {
			this.$router.replace('/create')
		}
	},
	mounted() {
		setTimeout(()=>{
			this.companySeal1 = createSeal(this.authInfo.company_name, this.$refs.logo1)
			this.companySeal2 = createSeal(this.authInfo.company_name, this.$refs.logo2)
			this.companySeal3 = createSeal(this.authInfo.company_name, this.$refs.logo3)
			this.companySeal4 = createSeal(this.authInfo.company_name, this.$refs.logo4)
			this.companySeal5 = creatLogo(this.authInfo.company_name)
		}, 200)

	},
	unmounted() {
		s_removeItem('authInfo')
	},
	computed: {
		...mapGetters({
			userInfo: 'getUserInfo'
		})
	},
	methods: {
		...mapActions({
			setUserInfo: 'SETUSERINFO',
			setCompany: 'SETCOMPANY'
		}),
		handleInput(key) {
			this.ruleForm[key] = this.ruleForm[key].replace(/[^Za-z0-9]/g, '')
		},
		selectLogo(index) {
			this.tmpIndex = index
			var forms = new FormData()
			var configs = {
				headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + getToken() }
			}
			forms.append('file', this['companySeal'+index].file)

			axios.post(`${this.baseApi}/api/v2/upload/image?filename=file&type=company`, forms, configs).then(res=>{
				if(res.data.code === 200) {
					this.ruleForm.logo = res.data.data.url
					this.logo_tmp = res.data.data.url
				}else if (res.data.code === 410000) {
					this.$message.error('请登录')
					removeToken()
					this.$router.push('/login')
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		/* 上传logo ------------- start */
		handleLogoSuccess(res, file) {
			if (res.code !== 200) {
				if (res.code === 410000) {
					this.$message.error('请登录')
					removeToken()
					this.$router.push('/login')
				} else {
					this.$message.error(res.msg)
				}
			}else {
				this.tmpIndex = 0
				this.logo_tmp = URL.createObjectURL(file.raw)
				this.ruleForm.logo = res.data.url
				this.logoProgress = 0
			}
		},
		beforeLogoUpload(file) {
			this.logo_tmp = ''
			this.ruleForm.logo = ''
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
			const isLt2M = file.size / 1024 / 1024 < 5
			if (!isJPG) {
				this.$message.error('上传LOGO图片只能是 JPG 或 PNG 格式!')
			}
			if (!isLt2M) {
				this.$message.error('上传LOGO图片大小不能超过 5MB!')
			}
			return isJPG && isLt2M
		},
		handleLogoProgress(event) {
			this.logoProgress = Math.floor(event.percent)
		},
		/* 上传logo ------------- end */

		/* 上传营业执照 ------------- start */
		handleLicenseSuccess(res, file) {
			if (res.code !== 200) {
				if (res.code === 410000) {
					this.$message.error('请登录')
					removeToken()
					this.$router.push('/login')
				} else {
					this.$message.error(res.msg)
				}
			}else {
				this.license_tmp = URL.createObjectURL(file.raw)
				this.ruleForm.license = res.data.url
				this.licenseProgress = 0
			}
		},
		beforeLicenseUpload(file) {
			this.license_tmp = ''
			this.ruleForm.license = ''
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
			const isLt2M = file.size / 1024 / 1024 < 5
			if (!isJPG) {
				this.$message.error('上传营业执照图片只能是 JPG 或 PNG 格式!')
			}
			if (!isLt2M) {
				this.$message.error('上传营业执照图片大小不能超过 5MB!')
			}
			return isJPG && isLt2M
		},
		handleLicenseProgress(event) {
			this.licenseProgress = Math.floor(event.percent)
		},
		/* 上传营业执照 ------------- end */

		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$axios({
						url: '/ent/v3/usercenter/company/create',
						method: 'POST',
						data: this.ruleForm,
						shake: true
					}).then(res => {
						if (res.code === 200) {
							this.userInfo.company_status = 'apply'
							// this.userInfo.realname = this.ruleForm.realname
							this.ruleForm.company_name = this.ruleForm.company
							this.setUserInfo(this.userInfo)
							this.setCompany(this.ruleForm)
							l_setItem('USERINFO', this.userInfo)
							this.$router.replace('/inReview')
						}
					})
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		toIndex() {
			this.$router.replace('/index')
		}
	}
}
</script>

<style lang='less' scoped>

.main {
	padding: 50px 100px 100px;
	background-color: #fff;
	.title {
		padding-bottom: 20px;
		border-bottom: 1px dashed #ededed;
	}
	/deep/.auth-form {
		.el-form-item__label {
			padding: 0 !important;
		}
		.el-input__inner {
			height: 44px;
			background-color: #f9f9f9;
		}
		.el-form-item__label {
			font-size: 16px;
			color: #333;
		}
	}
	.img-icon {
		width: 20px;
		height: 20px;
	}
	/deep/.el-upload {
		width: 100px;
		height: 100px;
		border-radius: 100px;
		border: 1px dashed #e3e3e3;
		background-color: #f9f9f9;
		overflow: hidden;
		.file-img {
			width: auto;
		}
	}

    .logo_tmp {
        width: 490px;
        height: 90px;
        background: #F9F9F9;
        border: 1px dashed #e3e3e3;
        z-index: 3;
        border-radius: 16px;
        position: relative;
        padding: 0 10px;
        &::before {
            position: absolute;
            content: '';
            width: 5px;
            height: 5px;
            border: 1px dashed #e3e3e3;
            border-bottom-color: transparent;
            border-right-color: transparent;
            transform: rotate(45deg);
            left: 45px;
            top: -5px;
            z-index: 5;
            background: #F9F9F9;
        }
        .myLogo {
            width: 70px;
            height: 70px;
            border-radius: 100%;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            .companySeal {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                z-index: 5;
            }
            .check-icon {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                z-index: 6;
            }
        }
    }
	.yyzz {
		/deep/.el-upload {
			width: 100px;
            border-radius: 16px;
		}
	}
	.el-button {
		width: 200px;
		height: 52px;
		margin-top: 80px;
	}
}
/deep/.el-upload-list .el-upload-list__item,
/deep/.el-upload {
	display: flex;
	align-items: center;
	justify-content: center;
}

</style>