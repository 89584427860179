// 将base64转换为文件(file 格式)
function dataURLtoFile(dataurl, filename) {
	var _arr = dataurl.split(','), mime = _arr[0].match(/:(.*?);/)[1],
		bstr = atob(_arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new File([ u8arr ], filename, { type: mime })
}
function createSeal(company, img){
	var canvas = document.createElement("canvas")
	canvas.width = 140
	canvas.height = 140
	var context = canvas.getContext('2d')

	// 绘制印章边框
	var width=canvas.width/2
	var height=canvas.height/2

	context.drawImage(img, 0, 0, 140, 140)

	// 绘制印章单位
	context.translate(width, height)// 平移到此位置,
	context.font = '11px Helvetica'
	context.fillStyle = "#fff"
	var count = company.length// 字数
	var angle = 3.2*Math.PI/(2.5*(count - 1))// 字间角度
	var chars = company.split("")
	var c
	for (var i = 0; i < count; i++){
		c = chars[i]// 需要绘制的字符
		if(i==0)
			context.rotate(5*Math.PI/6)
		else
			context.rotate(angle)
		context.save()
		context.translate(55, 0)// 平移到此位置,此时字和x轴垂直
		context.rotate(Math.PI/2)// 旋转90度,让字平行于x轴
		context.fillText(c, 0, 5)// 此点为字的中心点
		context.restore()
	}
	let dataUrl = canvas.toDataURL("image/png")
	return {
		file: dataURLtoFile(dataUrl, `${company}_logo.png`),
		url: dataUrl
	}
}

export default createSeal